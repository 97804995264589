<template>
  <div class="wrap">
    <div class="nav_bar">
      <van-icon
        name="arrow-left"
        size="20"
        style="position: absolute; left: 18px; top: 24px"
        @click="onClickLeft"
        v-show="!backIcon"
      />
      <span style="font-size: 20px; font-weight: bold">{{
        $route.query.title
      }}</span>
    </div>

    <div class="list">
      <div class="list-item">
        <div class="item-top green">
          <div class="flex-between-center">
            <div class="item-top-left">
              <div class="fs18 mb12">图文咨询</div>
              <div class="fs12">与患者实时沟通更便捷</div>
            </div>
            <div class="item-top-right">
              <!-- <van-button round size="mini" plain @click="toConsultPage('1')">立即进入</van-button> -->
              <van-button round size="mini" plain>立即进入</van-button>
            </div>
          </div>
        </div>
        <div class="item-bottom flex-around-center">
          <!-- <div class="bottom-left" @click="toConsultPage('1')"> -->
          <div class="bottom-left">
            <div class="dark fs18 mb8">{{ consultData.consultNeedReply }}</div>
            <div class="normal">待回复</div>
          </div>
          <!-- <div class="bottom-right" @click="toConsultPage('2')"> -->
          <div class="bottom-right">
            <div class="dark fs18 mb8">{{ consultData.consultInReply }}</div>
            <div class="normal">咨询中</div>
          </div>
        </div>
      </div>
      <div class="list-item">
        <div class="item-top blue">
          <div class="flex-between-center">
            <div class="item-top-left">
              <div class="fs18 mb12">视频咨询</div>
              <div class="fs12">与患者面对面交流病情</div>
            </div>
            <div class="item-top-right">
              <!-- <van-button round size="mini" plain @click="toVideoPage">立即进入</van-button> -->
              <van-button round size="mini" plain>立即进入</van-button>
            </div>
          </div>
        </div>
        <div class="item-bottom flex-around-center">
          <div class="bottom-left">
            <div class="dark fs18 mb8">{{ consultData.videoNeedReply }}</div>
            <div class="normal">待回复</div>
          </div>
          <div class="bottom-right">
            <div class="dark fs18 mb8">{{ consultData.videoInReply }}</div>
            <div class="normal">咨询中</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWorkHomePage } from "@/api/yisheng";
export default {
  name: "list",
  data() {
    return {
      consultData: {},
      backIcon: localStorage.getItem("routerHistory"),
    };
  },
  created() {
    this.getConsultData();
  },
  methods: {
    getConsultData() {
      getWorkHomePage().then((res) => {
        this.consultData = res.data.data;
     
      });
    },
    toConsultPage(status) {
      this.$router.push({
        path: "/yisheng/consult",
        query: {
          status,
        },
      });
    },
    onClickLeft() {
      this.$router.push({ path: "/menu" });
    },
    toVideoPage() {
      this.$router.push({
        path: "/yisheng/video",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  padding-top: 20px;
}
.nav_bar {
  text-align: center;
  color: #323233;
  padding-bottom: 20px;
}

.title {
  text-align: center;
  margin-top: 10px;
  font-size: 18px;
}
.list {
  margin-top: 3px;
  padding: 12px;
  .list-item {
    margin-bottom: 12px;
    .item-top {
      padding: 18px 12px;
      color: #fff;
      &.green {
        background-color: #1bc3a2;
      }
      &.blue {
        background-color: #4885e0;
      }
    }
    .item-bottom {
      background-color: #fff;
      padding: 12px 0;
      .bottom-left,
      .bottom-right {
        text-align: center;
      }
    }
  }
}
</style>
